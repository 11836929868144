import ghidra_cloud_logo from "../assets/ghidra-cloud-logo.png";
import {useAuth0} from "@auth0/auth0-react";
import {Navigate} from "react-router";
export default function Home() {
    const {
        isAuthenticated,
        loginWithRedirect,
    } = useAuth0();

    return (
        isAuthenticated ? <Navigate to="/servers"></Navigate> : <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <h1 style={{fontSize: "100px", padding: "100px 0 0 100px"}} >On Demand Ghidra Servers</h1>
                </div>
                <div className="col-md-6"><img src={ghidra_cloud_logo} alt="logo" className="w-100"></img></div>

                <div className="row row-cols-1 row-cols-md-3 my-4 text-center">
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Free</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$0<small
                                    className="text-muted fw-light">/mo</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>5 total servers</li>
                                    <li>1 running server</li>
                                    <li>Email support</li>
                                </ul>
                                <button type="button" className="w-100 btn btn-lg btn-outline-primary" onClick={() => loginWithRedirect()}>
                                    Sign up for free
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm">
                            <div className="card-header py-3">
                                <h4 className="my-0 fw-normal">Pro</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">$15<small
                                    className="text-muted fw-light">/mo</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>Unlimited total servers</li>
                                    <li>3 running server</li>
                                    <li>Email support</li>
                                </ul>
                                <button type="button" className="w-100 btn btn-lg btn-primary disabled">Coming Soon</button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-primary">
                            <div className="card-header py-3 text-white bg-primary border-primary">
                                <h4 className="my-0 fw-normal">Enterprise</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">contact us  $<small
                                    className="text-muted fw-light">/mo</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>30 users included</li>
                                    <li>15 GB of storage</li>
                                    <li>Email support</li>
                                </ul>
                                <a className="btn w-100 btn btn-lg btn-primary" href="mailto:ghidra-cloud@disassembler.io?subject = Feedback&body = Message">Contact Us</a>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>)
}
