import React, {useState} from "react";
import {useAxiosClient} from "../utils/axiosHelpers";
import {useNavigate} from "react-router";

import ghidra_logo from '../assets/ghidra_logo.png'
export default function NewServer() {
    const [ name, setName] = useState("");
    const [ description, setDescription] = useState("");
    const [ error, setError] = useState(false);
    const [ client ] = useAxiosClient();
    const navigate = useNavigate();

    const addServer = () => {
        setError(false);
        client.post('/servers/', {
            name: name,
            description: description
        }).then(() => {
            navigate(`/servers/${name}`)
        }).catch(()=>{
            setError(true);
        })
    }

    return (
        <div className="container mt-4">
            <div className="col-xl-8 offset-xl-2">
                <div className="row">
                    <div className="col-4">
                        <img className="w-75" alt="derp" src={ghidra_logo}/>
                    </div>
                    <div className="col-8">
                        <h1>Create a new Ghidra Collaboration Server</h1>
                        <p>Ghidra can support multiple users working together on a single project. Individual Ghidra users launch and work on their own local copies of a particular Ghidra project but check changes into a common server containing all commits to that server.</p>
                        <p>Once your server is created you will need to <span className="fw-bold">enable</span> it in order to use it from Ghidra. Connection details will be provided from the server details page</p>
                    </div>
                </div>
                <hr className="py-1"/>
                <div >
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label fw-bold" >Server Name</label>
                        <input type="text" className="form-control" id="name" aria-describedby="nameHelp"
                               value={name} onChange={(e) => {setName(e.target.value)}} />
                        <div id="nameHelp" className="form-text">Server Names must be DNS compatiable (letters, numbers and '-')</div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputPassword1" className="form-label fw-bold" >Description</label>
                        <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Server Description"
                               value={description} onChange={(e) => {setDescription(e.target.value)}} />
                    </div>

                    {error && (<div className="alert alert-danger" role="alert">
                        Server Creation Failed. Your name must be DNS friendly (letters, numbers and "-") and must be unique
                    </div>)}

                    <button className="btn btn-success text-white" onClick={addServer}>Create Server</button>
                </div>

            </div>

        </div>
    )
}