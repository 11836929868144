import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    if (isAuthenticated && user) {
        return (
            isAuthenticated && user && (
                <div className="container">

                    <h2>{user.name}</h2>
                    <p>{user.email}</p>
                    <p>Subscription Level: {user["https://api.ghidra-dev.disassembler.io/subscription_level"]}</p>
                    <p>{JSON.stringify(user)}</p>
                </div>
            )
        )
    } else {
        return <div>Not Signed In</div>
    }
};

export default Profile;