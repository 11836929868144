import React from "react";
import {
    BrowserRouter as Router,
    Route
} from "react-router-dom";
import LoginButton from "./components/LoginButton";
import Profile from "./components/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./components/LogoutButton";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import NavBar from "./components/NavBar";
import Loading from "./components/Loading";
import Servers, {SelectedServer} from "./views/Servers";
import NewServer from "./views/NewServer";
import {Routes} from "react-router";
import Servs from "./views/Servs";
import Home from "./views/Home";
initFontAwesome();

export default function App() {
    const { isLoading, error } = useAuth0();

    if (error) {
        return <div>
            <h2>Error</h2>
            <div>Oops... {error.message}</div>
            <div>
              <LoginButton></LoginButton>
              <LogoutButton></LogoutButton>
            </div>
        </div>;
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Router >
            <NavBar />
            <div id="app">
                <div>
                    <Routes>
                        <Route index  element={<Home/>} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="servers" element={<Servs />}>
                            <Route path="new" element={ <NewServer />} />
                            <Route path=":serverName" element={ <SelectedServer />} />
                            <Route index element={<Servers />} />
                        </Route>
                    </Routes>
                </div>
            </div>
        </Router>
    );
};
