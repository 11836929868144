import {getConfig} from "../config";
import {useAuth0} from "@auth0/auth0-react";
import {useCallback, useEffect, useState} from "react";
import {default as axios} from "axios";

export function useAxiosClient(): any {
    const {audience} = getConfig();
    const {getAccessTokenSilently} = useAuth0();
    const [client, setClient] = useState({});

    let apiUrl = ""
    if (window.location.host === 'localhost:3000') {
        apiUrl = "http://localhost:3000"
    } else {
        apiUrl = `${audience}` as string
    }

    const createClient = useCallback(async () => {
        const token = await getAccessTokenSilently();
        const axiosclient = axios.create({
            baseURL: apiUrl,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        setClient({
            delete: axiosclient.delete,
            post: axiosclient.post,
            put: axiosclient.put
        })
    }, [apiUrl, getAccessTokenSilently]);

    useEffect(() => {
        createClient();
    }, [createClient]);


    return [client]
}


function useAxios(url: string, method: string, requestData: any) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const {audience} = getConfig();
    const {getAccessTokenSilently} = useAuth0();

    let apiUrl = ""
    if (window.location.host === 'localhost:3000') {
        apiUrl = "http://localhost:3000"
    } else {
        apiUrl = `${audience}` as string
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        const token = await getAccessTokenSilently()
        try {
            const { data: response } = await axios.request({
                method: 'get',
                url:`${apiUrl}${url}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setData(response);
        } catch (error: any) {
            setError(error)
        }
        setLoading(false);
    }, [apiUrl, getAccessTokenSilently, url]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return [data, loading, fetchData, error]
}


export function useFetchData(url: string): any {
    const [data, loading, reload, error] = useAxios(url, 'get', null)
    return [data, loading, reload, error]
};