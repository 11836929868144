import React, {useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../components/Loading";
import {useAxiosClient, useFetchData} from "../utils/axiosHelpers";
import {Link, useParams} from "react-router-dom";
import {useNavigate} from "react-router";

function SelectedServerFirewallRules(props: any) {
    const [ cidrs, setCidrs] = useState(props.allowed_cidrs);

    return (<div className="container mt-3">
        <div className="card">
            <div className="card-header">
                <div className="fs-5 fw-bold">Firewall Configuration</div>
            </div>
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Allowed CIDRs</label>
                    <input type="email" className="form-control" value={cidrs} onChange = {(e) => {setCidrs(e.target.value)}}/>
                        <div className="form-text">
                            Enter a comma seperated list of valid cidr ranges.
                             </div>
                </div>
                <div className="row mt-2">
                    <div className="col-9 me-auto">
                        <div className="fw-bold">In addition to username/password you can also lock down network access to your ghidra server.</div>
                        <div className="mb-2">Access to the server will be restricted to those cidrs.
                            The default of "0.0.0.0/0" will allow network access from any source.</div>
                    </div>
                    <div className="col-auto">
                        <Button color="primary" onClick={() => {props.cidrsChanged(cidrs)} }>
                            Set Firewall
                        </Button>
                    </div>
                </div>
                { props.error && (<div className="alert alert-danger mt-2" role="alert">
                    This firewall configuration is invalid: {props.error}
                </div>)}

            </div>
        </div>
    </div>)
}
function SelectedServerUsers(props: any) {
    const [client] = useAxiosClient()
    const [ emailAddress, setEmailAddress] = useState("");
    const [ username, setUsername] = useState("");
    const [ role, setRole] = useState('USER');
    const [ addUserError, setAddUserError] = useState(false);
    const [ serverUsers, loadingserverUsers, reload ] = useFetchData(`/servers/${props.serverName}/users`)
    const serverName = props.serverName

    const removeUser = (user: any) => {
        client.delete(`/servers/${serverName}/users/${user.email}`, {
        }).then(()=> {
            reload()
        })
    }
    const addUser = () => {
        client.post(`/servers/${props.serverName}/users`, {
            email: emailAddress,
            username: username,
            role: role
        }).then(()=>{
            reload()
        }).catch(() => {
            setAddUserError(true)
        })
    }

    const userList = !loadingserverUsers && serverUsers.map( (user: any) => {
        return (
            user.role !== "OWNER" && <div className="row py-2" key={user.email}>
                <div className="col-auto me-auto">
                    <span className="fw-bold">{user.email}</span> / { user.username }
                </div>
                <div className="col-auto">
                    Role:{user.role}
                    <span className="btn btn-outline-primary ms-2" onClick={() => {removeUser(user)}}>Remove</span>
                </div>
            </div>

        )
    })

    return ((<div className="container">
        <div className="card">
            <div className="card-header">
                <div className="fs-5 fw-bold">Manage access</div>
            </div>
            <div >
                <ul className="list-group list-group-flush">
                    <li className="list-group-item">

                        <div className="row g-2 align-items-center">
                            <div className="col-6">
                                <input type="email" id="inputPassword6" className="form-control"
                                       aria-describedby="passwordHelpInline" placeholder="Email Address"
                                       value={emailAddress} onChange = {(e) => {setEmailAddress(e.target.value)}} />
                            </div>
                            <div className="col-auto">
                                <input type="email" id="inputPassword6" className="form-control"
                                       aria-describedby="passwordHelpInline" placeholder="Username"
                                       onChange = {(e) => {setUsername(e.target.value)}}
                                />
                            </div>
                            <div className="col-auto">
                                <select className="form-select" aria-label="Default select example" value={role} onChange = {(e) => {setRole(e.target.value)}}>
                                    <option>Select Role</option>
                                    <option value="ADMIN">ADMIN</option>
                                    <option value="USER">USER</option>
                                </select>
                            </div>
                            <div className="col-auto">
                                <div className="btn btn-success text-white" onClick={() => {addUser()} }>Add user</div>
                            </div>

                        </div>
                        { addUserError && (<div className="alert alert-danger mt-2" role="alert">
                            This user cannot be added.
                        </div>)}

                    </li>
                    <li className="list-group-item">
                        <div className="mb-2">Adding users grants them access to your ghidra server.
                            You will still need to manage access to individual repositories from the ghidra client.
                            Users can log in with the username you specify and the default password <span className="label">changeme</span>.</div>
                        {userList}
                    </li>
                </ul>

            </div>
        </div>
    </div>))
}

function DeleteServer(props: any) {
    const [ showRealDelete, setShowRealDelete ] = useState(false)
    const navigate = useNavigate();
    const [ client ] = useAxiosClient();
    const serverName = props.serverName
    const deleteServer = (serverName: string|undefined) => {
        client.delete(`/servers/${serverName}`).then(() => {
            navigate("/servers")
        })
    }

    return (<div className="container mt-3">
        <div className="card">
            <div className="card-header">
                <div className="fs-5 fw-bold">Delete</div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-auto me-auto">
                        <div className="fw-bold">Delete this server</div>
                        <div className="mb-2">Once you delete, your data is deleted. This is no recovery process.</div>
                    </div>
                    <div className="col-auto">
                        {showRealDelete && <span><Button color="danger" onClick={() => {deleteServer(serverName)} }>
                                    Really delete this server
                                </Button>&nbsp;</span>}
                        <Button color="danger" onClick={() => {setShowRealDelete(true)} }>
                            Delete Server
                        </Button>

                    </div>
                </div>

            </div>
        </div>
    </div>)
}

function ServerDetails(props: any) {
    const [ client ] = useAxiosClient();
    const { user } = useAuth0();

    const serverUsers = props.serverUsers
    const serverDetails = props.serverDetails
    const serverName = props.serverName

    const getUsername = () => {
        if (!serverUsers || !serverUsers.find) return "";
        return serverUsers.find((u: any) => {return u.email === user?.email }).username
    }


    const toggle = () => setModal(!modal);
    const getPassword = () => {
        client.post(`/servers/${serverName}/token`).then((result:any) => {
            toggle()
        })
    };

    const [modal, setModal] = useState(false);

    const popup = (<Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Password Reset</ModalHeader>
        <ModalBody>
            <p>Your password has been reset to the default "changeme"</p>
            <p>You must log in using the ghidra client to change your password within 24 hours</p>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={toggle}>
                Ok
            </Button>{' '}
        </ModalFooter>
    </Modal>)

    return (<div className="container mb-3">
        { popup }
        <div className="card">
            <div className="card-header">
                Server Details
            </div>
            <div className="card-body">
                <table className="table">
                    <tbody>
                    <tr><td>Server Name:</td>
                        <td>
                            <div className="fw-bold">{  `${serverName}.ghidra-dev.disassembler.io`}</div>
                            { (serverDetails.status !== "RUNNING") && <div className="alert alert-warning mt-2" role="alert">
                                <p>Your server must be <span className="fw-bold">RUNNING</span> in order to access from the ghidra client.
                                    Use the "Start Server" Button to enable access.</p>
                                <p>It may be 2 minutes after enabling for a server to become available. </p>
                            </div>}
                            { (serverDetails.status === "RUNNING") &&
                                <div className="alert alert-primary mt-2" role="alert">
                                    <h4 className="alert-heading">Notes on Server Names</h4>
                                    <p>
                                        Internally ghidra will store a canonical DNS name for your project, which may change over time.</p>
                                        <p>You can update the <code>SERVER</code> field of your <code>[PROJECT].rep/project.prp</code> file to point to the above name to maintain access as shown below:

                                        <pre><code>{`
<?xml version="1.0" encoding="UTF-8"?>
<FILE_INFO>
    <BASIC_INFO>
        <STATE NAME="OWNER" TYPE="string" VALUE="..." />`}
        <span className="fw-bold">{` 
        <STATE NAME="SERVER" TYPE="string" VALUE="${serverName}.ghidra-dev.disassembler.io" />`}</span>
        {`
        <STATE NAME="REPOSITORY_NAME" TYPE="string" VALUE="..." />
        <STATE NAME="PORT_NUMBER" TYPE="int" VALUE="13100" />
    </BASIC_INFO>
</FILE_INFO>`}</code></pre>
                                    </p>

                                </div>
                            }
                        </td></tr>
                    <tr><td>Version:</td><td>Ghidra Server 10.2.2 </td></tr>
                    <tr><td>Status:</td><td>{ serverDetails.status} {props.showBlinker && <i className="fa-solid fa-rotate-right blink_me"></i>}</td></tr>
                    <tr><td>ID:</td><td>{ serverDetails.uuid}</td></tr>
                    <tr><td>Enabled:</td><td>{ `${serverDetails.enabled}`}</td></tr>
                    <tr><td>Username:</td><td>{ getUsername() }</td></tr>
                    <tr><td>Password:</td>
                        <td>
                            <div className="row">
                                <div className="col-9">
                                    <div>The default password is <span className="label label-default">changeme</span></div>
                                    <div>You will be required to change your password on first login.</div>
                                </div>
                                <div className="col-3">
                                    <div className="btn btn-outline-primary" onClick={() => {getPassword()}}>Reset Password</div>
                                </div>
                            </div>
                    </td></tr>
                    </tbody>

                </table>

            </div>
        </div>
    </div>)
}
export function SelectedServer() {
    const { serverName } = useParams();
    const [ serverDetails, , reloadDetails ] = useFetchData(`/servers/${serverName}`)
    const [ client ] = useAxiosClient();
    const [ serverUsers ] = useFetchData(`/servers/${serverName}/users`)
    const [ firewallError, setFirewallError ] = useState("")
    const [ showBlinker, setShowBlinker ] = useState(false);

    const toggleServer = () => {
        let v = true;
        if (serverDetails.enabled) {
            v = false;
        }
        client.put(`/servers/${serverName}`, {
            name: serverName,
            enabled: v
        }).then(() => {
            reloadDetails()
        })
    }


    useEffect(() => {
        const interval = setInterval(() => {
            if (serverDetails && serverDetails.status !== "NOT_RUNNING" && serverDetails.status !== "RUNNING") {
                setShowBlinker(true);
                setTimeout(() => setShowBlinker(false), 3000);
                reloadDetails()
            }
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [reloadDetails, serverDetails]);

    const setCids = (cidrs: string) => {
        client.put(`/servers/${serverName}`, {
            name: serverName,
            allowed_cidrs: cidrs
        }).then(() => {
            setFirewallError("")
            reloadDetails()
        }).catch((result: any)=>{
            setFirewallError(JSON.stringify(result.response.data.errors.json.allowed_cidrs))
        })
    }

    let shouldAllowEnabledUpdate = serverDetails && (serverDetails.status === "NOT_RUNNING" || serverDetails.status === "RUNNING")

    let enableDisableText = "Start Server"
    let enableDisableColor = "success"
    if (serverDetails && serverDetails.enabled) {
        enableDisableText = "Stop Server"
        enableDisableColor = "primary"
    }

    return (
        !serverDetails ? <div></div> : <div>
            <div className="container-fluid bg-light">
                <div className="row py-3 ps-5">
                    <div className="col-auto me-auto ps-5 fs-4 text-decoration-none fw-bold">
                        <Link to="/">{serverDetails.creator_email} / {serverName}</Link>
                    </div>
                    <div className="col-auto">
                        <Button
                            disabled={!shouldAllowEnabledUpdate}
                            color={enableDisableColor}
                            className="text-white mx-2"
                            onClick={() => {toggleServer()} }
                        >
                            {enableDisableText}
                        </Button>
                    </div>
                </div>

            </div>
            <div className={"container mt-5"}>

            </div>

            { <ServerDetails showBlinker={showBlinker} serverName={serverName} serverDetails={serverDetails} serverUsers={serverUsers}></ServerDetails>}
            { <SelectedServerUsers serverName={serverName}></SelectedServerUsers> }
            { serverDetails && <SelectedServerFirewallRules allowed_cidrs={serverDetails.allowed_cidrs}
                                                            error={firewallError}
                                                            cidrsChanged={(cidrs: string)=>{setCids(cidrs)}}></SelectedServerFirewallRules>}
            { <DeleteServer serverName={serverName}></DeleteServer>}


        </div>
    )
}

function Server(props: {server: any}) {
    const created_at = new Date(props.server.created_at)
    return (<li className="list-group-item">
        <div className="fs-4">
            <Link to={`/servers/${props.server.name}`} className="me-2 text-decoration-none" >{props.server.name}</Link>
            <span className="label m-1 mb-1">OWNER</span>
        </div>
        <p> {props.server.description}</p>
        <p> </p>
        <div className="text-muted">
            <span className="badge bg-secondary me-2">{ props.server.status}</span>
            <span className="me-2">{ props.server.creator_email} </span>
            <span className="me-2">Users: 1 </span>
            { created_at.toLocaleString() }
        </div>
    </li>)

}

export const ServerList = () => {
    const [ servers, loading, reload ] = useFetchData('/servers/')

    if (!servers) {
        return <div></div>
    } else {
        const serverList = loading ? <div>"loading"</div> :
            servers.sort((a:{created_at:string},b:{created_at:string}) => {return b.created_at.localeCompare(a.created_at)}).map((server: any) => {
                return <Server key={server.uuid} server={server}></Server>
        });

        return (<div className="container mt-5">
            <div className="row">
                <div className="col-xl-8 offset-xl-2">


                    <div className="hstack gap-3 pb-2">
                        <div className="fs-4"><i className="fa-solid fa-cube"></i> Servers</div>
                        <div className="ms-auto">
                            <div className="col">
                                <Link to="/servers/new" className="btn btn-success text-white me-1" > <i
                                    className="fa-regular fa-square-plus"></i> New</Link>
                                <Button color={"primary"} className="text-white" onClick={()=>{reload()}} disabled={loading}> <i
                                    className="fa-solid fa-rotate-right"></i></Button>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <ul className="list-group list-group-flush">
                            {serverList}
                        </ul>
                        {serverList.length === 0 && <div className="card-body fs-4">Start by creating a new server using the "New" button</div>}
                    </div>
                </div>
            </div>
        </div>)
    }
}


export default withAuthenticationRequired(ServerList, {
    onRedirecting: () => <Loading/>,
});
