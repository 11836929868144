import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ghidra_cloud_logo from "../assets/ghidra-cloud-logo.png";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Button
} from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

const NavBar = () => {

    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    return (
        <div>
            <Navbar color="dark" expand="md" dark={true}>
                <NavbarBrand href="/">
                    <img alt="logo" style={{width: "100px", margin: "-30px 0 -50px 0"}} src={ghidra_cloud_logo} />
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="me-auto" navbar>
                        {isAuthenticated && user && <NavItem>
                            <NavLink href="/servers/" className="fw-bold text-white">Servers</NavLink>
                        </NavItem>}

                    </Nav>
                    <Nav>
                        {!isAuthenticated && (
                            <NavItem>
                                <Button
                                    id="qsLoginBtn"
                                    color="primary"
                                    className="btn-margin"
                                    onClick={() => loginWithRedirect()}
                                >
                                    Log in
                                </Button>
                            </NavItem>
                        )}
                        {isAuthenticated && user && <UncontrolledDropdown >
                            <DropdownToggle nav id="profileDropDown" className="text-white fw-bold">
                                Profile
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem header>{user.name}</DropdownItem>
                                <DropdownItem
                                    tag={RouterNavLink}
                                    to="/profile"
                                    className="dropdown-profile"
                                >
                                    <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                                </DropdownItem>
                                <DropdownItem
                                    id="qsLogoutBtn"
                                    onClick={() => logoutWithRedirect()}
                                >
                                    <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                                    out
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>}
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
    );
};

export default NavBar;
